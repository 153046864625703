.grid-cell {
  --grid-column-span-mobile: 1;
  --grid-column-span-tablet: var(--grid-column-span-mobile);
  --grid-column-span-desktop: var(--grid-column-span-tablet);
  --grid-column-span: var(--grid-column-span-mobile);

  grid-column: span var(--grid-column-span);
}

.grid-cell_children-full-height > * {
  height: 100%;
}

@media screen(tablet) {
  .grid-cell {
    --grid-column-span: var(--grid-column-span-tablet);
  }
}

@media screen(desktop) {
  .grid-cell {
    --grid-column-span: var(--grid-column-span-desktop);
  }
}
