.gap-styles {
  --cms-styles-row-gap-mobile: 0rem;
  --cms-styles-row-gap-tablet: var(--cms-styles-row-gap-mobile);
  --cms-styles-row-gap-desktop: var(--cms-styles-row-gap-tablet);
  --cms-styles-row-gap: var(--cms-styles-row-gap-mobile);
  --cms-styles-column-gap-mobile: 0rem;
  --cms-styles-column-gap-tablet: var(--cms-styles-column-gap-mobile);
  --cms-styles-column-gap-desktop: var(--cms-styles-column-gap-tablet);
  --cms-styles-column-gap: var(--cms-styles-column-gap-mobile);

  gap: var(--cms-styles-row-gap) var(--cms-styles-column-gap);
}

@media screen(tablet) {
  .gap-styles {
    --cms-styles-column-gap: var(--cms-styles-column-gap-tablet);
    --cms-styles-row-gap: var(--cms-styles-row-gap-tablet);
  }
}

@media screen(desktop) {
  .gap-styles {
    --cms-styles-column-gap: var(--cms-styles-column-gap-desktop);
    --cms-styles-row-gap: var(--cms-styles-row-gap-desktop);
  }
}
