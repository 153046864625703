.storyblok-product-ratings__image {
  height: 100px;
  margin: 0 auto;
  width: 100px;
}

.storyblok-product-ratings__rating {
  font-size: 1.5rem;
  margin-bottom: calc(var(--spacing-2) * -1);
}

@media screen(tablet) {
  .storyblok-product-ratings__image {
    height: 150px;
    width: 150px;
  }

  .storyblok-product-ratings__button {
    --button-font-size: var(--type-scale-s-font-size);
    --button-letter-spacing: var(--type-scale-s-letter-spacing);
    --button-line-height: var(--type-scale-s-line-height);
    --button-icon-size: var(--type-scale-2xl-font-size);

    align-self: flex-start;
  }

  .storyblok-product-ratings__rating {
    font-size: 1.875rem;
  }
}
