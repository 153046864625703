.rounded-table {
  border-collapse: collapse;
  border-spacing: theme(borderSpacing.0);
}

.rounded-table td {
  padding: theme(spacing.3);
}

.rounded-table thead tr:first-child td:first-child {
  border-top-left-radius: theme(borderRadius.xl);
}

.rounded-table thead tr:first-child td:last-child {
  border-top-right-radius: theme(borderRadius.xl);
}

.rounded-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: theme(borderRadius.xl);
}

.rounded-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: theme(borderRadius.xl);
}
