.drawer-transition {
  transform: translateY(100%);
  transition-duration: theme(transitionDuration.150);
  transition-property: transform;
  transition-timing-function: theme(transitionTimingFunction.in-out);

  @media screen(tablet) {
    transform: translateX(100%);
  }
}

.drawer-transition_open {
  transform: translate(0);
}
