.meetergo-container {
  width: 100%;

  @media screen(mobile-sm) {
    height: 500px;
    min-height: 500px;
  }

  @media screen(mobile-md) {
    height: 665px;
    min-height: 570px;
  }

  @media screen(tablet) {
    height: 500px;
    margin-bottom: 0;
    min-height: 650px;
  }
}
