.product-battery {
  position: relative;
  width: 100%;
}

.product-battery__item-wrapper {
  @apply flex shrink-0 grow-0 snap-start flex-col overflow-x-hidden px-1;

  flex-basis: 46%;
}

.product-battery__slider-button {
  @apply top-1/2 -translate-y-1/2 transition-opacity;

  display: none;
  opacity: 0.25;
  position: absolute;
}

.product-battery:hover .product-battery__slider-button {
  opacity: 1;
}

@media screen(mobile) {
  .product-battery__item-wrapper {
    flex-basis: 30%;
  }
}

@media screen(tablet) {
  .product-battery__item-wrapper {
    flex-basis: calc(25%);
  }

  .product-battery__slider-button {
    display: flex;
  }

  .product-battery__slider-button[hidden] {
    display: none;
  }
}
