.image-card-container {
  /* stylelint-disable-next-line unit-no-unknown */
  border-radius: theme(borderRadius.2xl);
  position: relative;
}

.image-card-container--small {
  height: 110px;
  width: 150px;
}

.image-card-container--medium {
  height: 190px;
  width: 190px;
}

.image-card-container--large {
  height: 225px;
  width: 248px;
}

.image-card-container--borders {
  border-color: theme(borderColor.dark-primary-medium);
  border-width: theme(borderWidth.DEFAULT);
}

@media screen(tablet) {
  .image-card-container--small {
    height: 140px;
    width: 180px;
  }
}
