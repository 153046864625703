.further-information {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: theme(spacing.6);
}

.further-information-title {
  color: theme(textColor.dark-primary-max);
  font-style: normal;
  font-weight: theme(fontWeight.medium);

  @apply text-2xl;
}

.further-information-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: theme(spacing.9);
  padding: theme(borderRadius.none);
}

/* collapsibles have internal paddings causing the second column to shrink */
.further-information-column {
  flex: 1 1 auto;
}

.further-information-column > * {
  flex: 1 0 0;
}

/* -- collapsibles have internal paddings causing the second column to shrink */

.further-information-services {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: theme(borderRadius.none);
  padding: theme(borderRadius.none);
}

.further-information-contact-details {
  align-items: flex-start;
  align-self: stretch;
  background: theme(backgroundColor.light-primary-medium);
  /* stylelint-disable-next-line unit-no-unknown */
  border-radius: theme(borderRadius.3xl);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: theme(spacing.6);
  padding: theme(spacing.6);
}

.further-information-contact-details-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: theme(spacing.4);
  padding: theme(borderRadius.none);
}

.further-information-contact-details-section .icon {
  height: 24px;
  width: 24px;
}

.further-information-contact-details-section .title {
  color: theme(textColor.dark-primary-max);
  font-size: theme(fontSize.m);
  font-style: normal;
  font-weight: theme(fontWeight.medium);
  line-height: theme(lineHeight.m); /* 150% */
}

.further-information-contact-details-section .text {
  align-self: stretch;
  color: theme(textColor.dark-primary-strong);
  font-size: theme(fontSize.s);
  font-style: normal;
  font-weight: theme(fontWeight.normal);
  letter-spacing: theme(letterSpacing.s); /* 0.07px */
  line-height: theme(lineHeight.s); /* 150% */
}

.further-information-contact-details-section .description {
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: theme(borderRadius.lg);
  padding: theme(borderRadius.none);
}
