.header-mobile {
  align-items: center;
  background-color: var(--background-light-primary-low);
  border-bottom: 1px solid var(--content-dark-primary-low);
  display: flex;
  height: var(--sticky-header-height);
  justify-content: space-between;
  padding-top: 2px;
  position: sticky;
  top: 0;
  z-index: 30;

  @apply container;
}

@media screen(tablet) {
  .header-mobile {
    display: none;
  }
}
