@media screen(tablet) {
  .footer-banner-section-accordion {
    background-color: transparent;
    border-width: 0;
    margin-top: 0;

    :global {
      .accordion__transition_collapsed {
        max-height: none;
        overflow: visible;
      }

      .accordion-summary {
        background-color: transparent;
        margin-bottom: theme(spacing[3.5]);
        padding: 0;
        pointer-events: none;
        user-select: auto;
      }

      .accordion-summary__expand-icon-wrapper {
        display: none;
      }

      .accordion-summary__content {
        font-size: var(--type-scale-s-font-size);
        letter-spacing: var(--type-scale-s-letter-spacing);
        line-height: var(--type-scale-s-line-height);
        padding: 0;
      }

      .accordion-details {
        padding: 0;
      }
    }
  }
}
