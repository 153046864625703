.voucher-card-container {
  align-items: center;
  /* stylelint-disable-next-line unit-no-unknown */
  border-radius: theme(borderRadius.2xl);
  display: flex;
  flex-direction: column-reverse;
  gap: theme(spacing.4);
  height: 100%;
  justify-content: space-between;
  max-width: theme(width.80);
  padding: theme(spacing.6);
  position: relative;
  width: 100%;
}

.voucher-card-container::before,
.voucher-card-container::after {
  background-color: theme(backgroundColor.light-primary-low);
  border-radius: theme(borderRadius.full);
  content: '';
  height: theme(height.10);
  position: absolute;
  top: theme(spacing.44);
  width: theme(width.10);
}

.voucher-card-container::before {
  right: calc(-1 * theme(spacing.6));
}

.voucher-card-container::after {
  left: calc(-1 * theme(spacing.6));
}

@media screen(tablet) {
  .voucher-card-container {
    flex-direction: row;
    max-width: 100%;
    width: theme(width.1/2);
  }

  .voucher-card-container::before {
    right: theme(spacing.36);
    top: calc(-1 * theme(spacing.6));
  }

  .voucher-card-container::after {
    inset: auto theme(spacing.36) calc(-1 * theme(spacing.6)) auto;
  }
}
