.header-categories-menu-icon-button__arrow-up::before {
  border-bottom-color: theme(backgroundColor.light-primary-medium);
  border-bottom-width: 8px;
  border-left-color: theme(backgroundColor.light-brand-high);
  border-left-width: 8px;
  border-right-color: theme(backgroundColor.light-brand-high);
  border-right-width: 8px;
  bottom: 0;
  content: '';
  height: 0;
  left: theme(spacing.16);
  position: absolute;
  width: 0;
}

.header-categories-menu-icon-button__arrow-up:hover::before {
  border-left-color: theme(backgroundColor.light-brand-high);
  border-right-color: theme(backgroundColor.light-brand-high);
}
