.slider {
  --cms-slider-items-per-page-mobile: 1;
  --cms-slider-items-per-page-tablet: var(--cms-slider-items-per-page-mobile);
  --cms-slider-items-per-page-desktop: var(--cms-slider-items-per-page-tablet);
  --cms-slider-items-per-page: var(--cms-slider-items-per-page-mobile);
  --cms-slider-gap-mobile: 0rem;
  --cms-slider-gap-tablet: var(--cms-slider-gap-mobile);
  --cms-slider-gap-desktop: var(--cms-slider-gap-tablet);
  --cms-slider-gap: var(--cms-slider-gap-mobile);

  position: relative;
}

.slider__container {
  @apply scrollbar-hide;

  display: flex;
  margin-inline: calc(var(--viewport-padding) * -1);
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
}

.slider__container::after {
  content: '';
  flex: 0 0 auto;
  width: var(--viewport-padding);
}

/*
On `mobile` screen slider scrolls from edge-to-edge and highlight for the user
that it has items to scroll.
 */
.slider__item {
  flex: 0 0
    calc(
      (100% - var(--viewport-padding) * 2) / var(--cms-slider-items-per-page)
    );
  padding-inline: var(--viewport-padding)
    calc(var(--cms-slider-gap) - var(--viewport-padding));
  scroll-snap-align: start;
}

.slider__navigation-button {
  @apply transition-opacity;

  display: none;
  opacity: 0.25;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider:hover .slider__navigation-button {
  opacity: 1;
}

@media screen(tablet) {
  .slider {
    --cms-slider-items-per-page: var(--cms-slider-items-per-page-tablet);
    --cms-slider-gap: var(--cms-slider-gap-tablet);
  }

  /*
  On `tablet` and further screens we show only defined amount of items per row and
  utilize `gap` property instead of manipulations with `padding` on `mobile` screen.
   */
  .slider__container {
    gap: var(--cms-slider-gap);
    margin-inline: 0;
  }

  .slider__container::after {
    display: none;
  }

  .slider__item {
    flex: 0 0
      calc(
        100% / var(--cms-slider-items-per-page) -
          (
            var(--cms-slider-gap) / var(--cms-slider-items-per-page) *
              (var(--cms-slider-items-per-page) - 1)
          )
      );
    padding-inline: 0;
  }

  .slider__navigation-button {
    display: flex;
  }

  .slider__navigation-button[hidden] {
    display: none;
  }
}

@media screen(desktop) {
  .slider {
    --cms-slider-items-per-page: var(--cms-slider-items-per-page-desktop);
    --cms-slider-gap: var(--cms-slider-gap-desktop);
  }
}
