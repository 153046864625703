.redcare-toolbar-dialog__menu-item {
  color: var(--content-dark-primary-max);
  cursor: pointer;
  outline: none;
  padding: theme(spacing.3) theme(spacing.4) theme(spacing.3) theme(spacing.6);
}

.redcare-toolbar-dialog__menu-item:hover,
.redcare-toolbar-dialog__menu-item:focus-visible {
  background-color: var(--background-light-brand-low);
}
