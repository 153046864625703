.brand-illustration-info-card__content :where(h1, h2, h3, h4, h5, h6) {
  font-size: var(--type-scale-m-font-size);
  font-weight: var(--typeface-weight-medium);
  letter-spacing: var(--type-scale-m-letter-spacing);
  line-height: var(--type-scale-m-line-height);
}

.brand-illustration-info-card__content
  :where(h1, h2, h3, h4, h5, h6)
  :where(em) {
  color: var(--content-dark-accent);
}

/*
 `.cms-overlay-link` class is in richtext field of the current component.
  If a user applies custom CSS class to the link element a whole card becomes clickable.
  It makes the card accessible.
 */
.brand-illustration-info-card__content
  :where(a:global(.cms-overlay-link), :global(.cms-overlay-link) a) {
  text-decoration: none;
}

.brand-illustration-info-card__content
  :where(a:global(.cms-overlay-link), :global(.cms-overlay-link) a)::after {
  content: '';
  display: block;
  inset: 0;
  position: absolute;
}
