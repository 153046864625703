.slider {
  --cms-product-battery-slider-items-per-page: 2;

  position: relative;
}

.slider__container {
  @apply scrollbar-hide;

  display: flex;
  margin-inline: calc(var(--viewport-padding) * -1);
  overflow-x: auto;
  padding-block: var(--spacing-1) var(--spacing-3);
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
}

.slider__container::after {
  content: '';
  flex: 0 0 auto;
  width: var(--viewport-padding);
}

/*
On `mobile` screen slider scrolls from edge-to-edge and highlight for the user
that it has items to scroll.
 */
.slider__item {
  flex: 0 0
    calc(
      (100% - var(--viewport-padding) * 2) /
        var(--cms-product-battery-slider-items-per-page)
    );
  padding-inline: var(--viewport-padding) calc(var(--viewport-padding) * -1);
  scroll-snap-align: start;
}

.slider__navigation-button {
  @apply transition-opacity;

  display: none;
  opacity: 0.25;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider:hover .slider__navigation-button {
  opacity: 1;
}

@media screen(tablet) {
  .slider {
    --cms-product-battery-slider-items-per-page: 4;
  }

  /*
  On `tablet` and further screens we show only defined amount of items per row and
  utilize `gap` property instead of manipulations with `padding` on `mobile` screen.
   */
  .slider__container {
    margin-inline: 0;
  }

  .slider__container::after {
    display: none;
  }

  .slider__item {
    flex: 0 0 calc(100% / var(--cms-product-battery-slider-items-per-page));
    padding-inline: var(--spacing-2);
  }

  .slider__navigation-button {
    display: flex;
  }

  .slider__navigation-button[hidden] {
    display: none;
  }
}
