.frc :global(.frc-container) {
  @apply flex min-h-[4rem] items-center;
}

.frc :global(.frc-icon) {
  @apply shrink-0 fill-light-primary-strong stroke-dark-brand stroke-[1.4px];
}

.frc :global(.frc-button) {
  @apply my-2.5 min-h-[2rem] rounded bg-dark-brand text-light-primary-strong;
}

.frc :global(.frc-button:hover) {
  @apply bg-dark-brand;
}

.frc :global(.frc-banner) {
  display: none;
}
