.storyblok-services-list {
  --grid-columns-mobile: 1;
  --grid-columns: var(--grid-columns-mobile);
  --grid-columns-tablet: var(--grid-columns-mobile);
  --grid-columns-desktop: var(--grid-columns-mobile);

  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
}

@media screen(tablet) {
  .storyblok-services-list {
    --grid-columns: var(--grid-columns-tablet);
  }
}

@media screen(desktop) {
  .storyblok-services-list {
    --grid-columns: var(--grid-columns-desktop);
  }
}
