.flex-children-get-same-space > * {
  flex: 1;
}

@media screen(tablet) {
  .flex-children-get-same-space__tablet > * {
    flex: 1;
  }
}

@media screen(desktop) {
  .flex-children-get-same-space__mobile > * {
    flex: 1;
  }
}
